<template>
  <div class="container prow-40">
    <h1 class="title pt-20 pb-10">申请权限须知</h1>
    <span class="time ml-8">2021/08/02</span>

    <div class="line"></div>

    <div class="mt-40 txt f-c-c f-col">
      
      <div class="pro-txt f-c-c f-col pcol-30">
        <div># 通过审核的摄影师 #</div>
        <div class="mt-10">请扫码添加幼享乐管理员微信</div>
      </div>

      <img class="notice-img" src="~@/assets/notice.png" />

      <div class="border-box mt-40 prow-30 f-fs-fs f-col">
        
        <h2 class="secod-title mt-50">下载分成比例</h2>

        <span class="rule mt-50">1、用户下载时可自动获得用户购买价格的70%现金。</span>
        <span class="rule ">2、照片现金收入将入账到APP的“钱包”中，可随时提现。</span>
        
        <h2 class="secod-title mt-50">拍摄规范要求</h2>

        <span class="rule mt-50">1、可使用单反或微单相机及长焦拍摄小朋友的活泼开心的画面。</span>
        <span class="rule ">2、应适当调整光圈、快门、ISO、色温等设置，须保证照片清晰度和美观度，
并且不得出现丑化人物形象的照片;不得拍摄容易被家长投诉的照片，不得拍摄对
于事实容易产生误会的照片。</span>
        <span class="rule ">3、相机时间设置必须为正确的拍摄当时的北京时间。</span>
        <span class="rule ">4、照片须为JPG格式，保持在1000万像素或3MB以上。</span>

        <h2 class="secod-title mt-50">上传规范及说明</h2>

        <span class="rule ">1、上传前，必须对拍摄照片进行基本审查和筛选后再上传。</span>
        <span class="rule ">2、上传照片不可额外增加文字、其他水印，且需删除对焦不实、曝光不正
常、内容杂乱等照片，禁止未经筛选照片直接上传，因天气不好导致拍摄的照片过
暗时，需要整体调亮照片曝光。</span>
        <span class="rule ">3、上传前，须使用Lightroom等处理软件进行的批量处理。</span>
        <span class="rule ">4、照片需要在当晚22:00前完成上传;建议使用谷歌Chrome浏览器。</span>
        <span class="rule ">5、电脑端上传网址：<a class="link" href="https://youxiangle.cn/yxl-admin/">https://youxiangle.cn/yxl-admin/</a></span>
        <span class="rule ">6、为保证上传顺利，建议每次批量上传照片数量在200张左右。</span>
        <span class="rule ">7、可以在电脑上打开多个浏览器页面，同时上传。</span>
        <span class="rule ">8、照片需要在网络环境稳定、流畅的环境下批量上传。</span>

        <h2 class="secod-title mt-50">注意事项及权限说明</h2>

        <span class="rule ">1、在拍摄期间，拍摄者不能做出违反场景规定或违反法律道德规定的行为。</span>
        <span class="rule ">2、在未征得甲方同意前，乙方不得以甲方名义开展除场景抓拍的其他工作。</span>
        <span class="rule ">3、未征得幼享乐及柏斯融媒体科技的同意，不得借用幼享乐或柏斯融媒体科
技的名义开展其他工作或活动。</span>
        <span class="rule ">4、幼享乐管理员将不定时检查照片墙内容，对于多次未按照平台要求上传照
片、接到场景或用户投诉、违规使用幼享乐或广东柏斯融媒体科技名义开展活动等
行为，将被取消上传权限，同时我司对情节严重者保留使用法律的权利</span>

        <h2 class="secod-title mt-50">著作权和肖像权</h2>

        <span class="rule ">您所拍摄和上传的照片不得侵犯其他任何第三方的专利权、著作权、商标权、
名誉权、肖像权、隐私权、物权或其他任何合法权益，影像著作权归拍摄人所有，
并且已经获得相关肖像权所有人的同意进行上传，不得侵犯他人肖像权，照片中主
体自然人的肖像权归被拍摄个人所有。您上传的照片在被保存/下载时，仅用于个
人收藏或使用，在未经广东柏斯融媒体科技或被拍摄人许可前，任何机构和个人不
得将照片或视频用于商业目的。</span>

        <h2 class="secod-title mt-50">免责声明</h2>


        <span class="rule ">幼享乐app仅为摄影作品的展示和销售平台，摄影师在拍摄期间如果发生人身
意外伤害或财产损失，需要自行承担法律责任和对应损失。幼享乐平台及其运营公
司：广东柏斯融媒体科技有限公司无任何责任。
*以上内容最终解释权归广东柏斯融媒体科技有限公司所有且本公司有权</span>

      </div>
      

    </div>
  </div>
</template>

<script>
export default {
  name: 'Notice'
};
</script>

<style lang="scss" scoped>
.container {
  background: #fff;
}
.title,
.txt {
  font-size: 0.28rem;
  color: #0a1738;
  line-height: 0.4rem;
  letter-spacing: 0.02rem;
}
.title {
  font-size: 0.36rem;  
}
.txt {
  font-size: 0.24rem;
}
.time {
  font-size: 0.2rem;
}
.line {
  position: absolute;
  width: 80vw;
  left: 0;
  right: 0;
  margin: 0.2rem auto;

  border-top: 0.02rem solid #606e79;
}
.pro-txt {
  letter-spacing: 0.02rem;
  color: #504046;
  div {
    background-color: #b5cff6;
  }
}
.notice-img {
  width: 60vw;
}
.border-box {
  width: 90vw;
  min-height: 8rem;
  border: 0.4rem dashed #f0f8fd;
  box-sizing: border-box;
  .secod-title {
    font-size: 0.3rem;
    background-color: #ffed9e;
    align-self: center;
  }
  .rule {
    text-indent: 2em;
    font-size: 0.22rem;
    line-height: 1.6;
  }
}
.link {
  background-color: #b5cff6;
}
.fs-22 {
  font-size: 0.22rem;
}

</style>
